import React, { useEffect, useCallback } from 'react'
import { KeyOutlined, UserOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Layout, Input, Row, Col, Typography, Button, Alert } from 'antd'
import { Form as FinalForm, Field as FinalFormField } from 'react-final-form'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../../actions/auth'

const Login = ({ doLogin, loginError, loginLoading, currentUser }) => {
  useEffect(() => {
    reset()
  }, [])

  const reset = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.get('token')) {
      localStorage.setItem('auth:refresh-token', urlParams.get('token'))
      window.location.href = '/training'
    }
  }, [])

  return (
    <Layout.Content>
      <Row>
        <Col span={12} offset={6}>
          <Typography.Title>Willkommen</Typography.Title>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default connect(
  (state) => ({
    loginError: state.auth.loginErrorMessage,
    loginLoading: state.auth.loginLoading,
    currentUser: state.auth.currentUser
  }),
  (dispatch) => ({
    doLogin: (email, password) => dispatch(login(email, password))
  })
)(Login)
