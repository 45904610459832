import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, Badge, Checkbox, Typography } from 'antd';
import { filter, map } from 'lodash';

const levelMapping = {
    debug: '#A5A5A5',
    info: 'geekblue',
    error: 'rgba(255, 0, 25, 1)',
    warn: 'rgba(207, 174, 0, 1)',
    trace: 'rgba(139, 69, 19, 1)',
};

const Log = ({ log }) => {
    const [enabledLevel, setEnabledLevel] = useState(['debug', 'info', 'error', 'warn']);

    return (
        <>
            <Checkbox.Group
                onChange={setEnabledLevel}
                style={{ marginBottom: 20 }}
                defaultValue={enabledLevel}
                options={map(levelMapping, (color, key) => ({
                    label: (
                        <Typography.Text>
                            <Badge color={color} /> {`${key.charAt(0).toUpperCase()}${key.substring(1)}`}
                        </Typography.Text>
                    ),
                    value: key,
                }))}
            />

            <List
                bordered
                size="small"
                dataSource={filter(log.entries, (row) => [...enabledLevel, 'divider'].includes(row.level))}
                renderItem={(item) =>
                    item.level === 'divider' ? (
                        <List.Item
                            style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            &nbsp;
                            {item.message !== undefined? item.message : ''}
                        </List.Item>
                    ) : (
                        <List.Item>
                            <Badge color={levelMapping[item.level]} /> <strong>{item.meta.file.replace('.js', ': ')}</strong>
                            {item.message}
                        </List.Item>
                    )
                }
            />
        </>
    );
};

Log.propTypes = {
    log: PropTypes.object.isRequired,
};

export default Log;
