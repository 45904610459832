import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { Card, Alert, Typography } from 'antd';
import Part from './part';
import { filter } from 'lodash';

const Unit = ({ unit, userId }) => {
    const [doRedirect, setDoRedirect] = useState(false);

    const goRating = useCallback(async () => {
        setDoRedirect(true);
    }, []);

    if (doRedirect) {
        const exercises = filter(unit.parts[0].elements, (e) => { return e.type === 'exercise';});
        // const ratings = "50-".repeat(exercises.length).slice(0, -1);
        return <Redirect to={{ pathname: "/training/ratingsim/"+userId+"/"+ratings+"/view", state: { data : unit, userId: userId } }} />;
    }
    return (
        <Card title={unit.title} style={{ marginBottom: 20 }} type="inner" extra={<Typography.Text strong>{`${unit.workPoints} Workpoints`}</Typography.Text>}>
            <Alert message={unit.description} style={{ marginBottom: 20 }} />

            {unit.parts.map((part, key) => (
                <Part key={key} part={part} />
            ))}
        </Card>
    );
}

Unit.propTypes = {
    unit: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
};

export default Unit;
