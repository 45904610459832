import React from 'react';
import PropTypes from 'prop-types';
import Unit from './unit';

const Workout = ({ workout, userId }) => workout.units.map((unit) => <Unit key={unit._id} unit={unit} userId={userId} />);

Workout.propTypes = {
    workout: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
};

export default Workout;
