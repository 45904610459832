import { forEach, isEmpty } from 'lodash';

// hasValidModeTimes is the validator
// for ensuring validity of 'modeValue'
// and 'modeTimeCap' values according
// to mode.
function hasValidModeTimes(values) {
    let isValid = true;
    forEach(values.training.units, (unit) => {
        forEach(unit.parts, (part) => {
            if(!isEmpty(part.mode)) {
                if(part.mode === "FT" || part.mode === "FQ") {
                    if(part.modeValue === undefined || part.modeValue === null || part.modeTimeCap === undefined || part.modeTimeCap === null) {
                        isValid = false
                    } else {
                        if (isValid === false) {
                            return false;
                        }
                        isValid = true
                    }
                } else if (part.mode === "AMRAP") {
                    if(part.modeTimeCap !== undefined) {
                        delete part.modeTimeCap;
                    }
                    if(part.modeValue === undefined || part.modeValue === null) {
                        isValid = false
                    } else {
                        if (isValid === false) {
                            return false;
                        }
                        isValid = true
                    }
                } else {
                    isValid = false
                }
            } else {
                isValid = false
            }
        });
    });
    return isValid;
}

export default hasValidModeTimes;
