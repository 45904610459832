import React from 'react'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import { Layout, Spin, Typography } from 'antd'
import Dashboard from './routes/dashboard'
import store from './store'
import { Provider } from 'react-redux'
import Login from './routes/auth/login'
import Header from './components/layout/header'
import { boot, Creators } from './actions/auth'
import Training from './routes/training'
import jwt_decode from 'jwt-decode'
import http from './lib/http'

const { Content, Footer } = Layout

function validateAndParseJWT () {
  const token = localStorage.getItem('auth:refresh-token')
  if (token === null) {
    return false
  }
  let d
  try {
    d = jwt_decode(token)
  } catch {
    return null
  }
  const currentDate = new Date()

  if (d.exp * 1000 < currentDate.getTime()) {
    return null
  } else {
    return d
  }
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
  let expired = true
  const token = localStorage.getItem('auth:refresh-token')
  if (token === null) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: '/sso',
              state: { form: props.location }
            }}
          />
        )}
      />
    )
  }
  let d
  try {
    d = jwt_decode(token)
  } catch {
    expired = true
    d = { exp: 0 }
  }
  const currentDate = new Date()

  if (d.exp * 1000 < currentDate.getTime()) {
    expired = true
  } else {
    expired = false
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        expired === false
          ? (
            <Component {...props} />
            )
          : (
            <Redirect
              to={{
                pathname: '/sso',
                state: { from: props.location }
              }}
            />
            )}
    />
  )
}

export default class App extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isAppInitializing: true
    }
  }

  async componentDidMount () {
    const token = localStorage.getItem('auth:refresh-token')
    if (token === null) {
      this.setState({ isAppInitializing: false })
    } else {
      const d = validateAndParseJWT()
      if (d === null) {
        this.setState({ isAppInitializing: false })
        return
      }
      await store.dispatch(boot())
      // fetch user and profile
      store.dispatch(Creators.userLoaded({ user: { data: {} }, profile: { data: {} } }))
      this.setState({ isAppInitializing: false })
    }
  }

  render () {
    const { isAppInitializing } = this.state

    return (
      <BrowserRouter>
        <Provider store={store}>
          <Layout className='layout'>
            <Header isAppInitializing={isAppInitializing} />

            <Content style={{ padding: '0 50px' }}>
              <Layout
                style={{
                  padding: '24px 0',
                  background: '#fff'
                }}
              >
                {isAppInitializing && (
                  <Content style={{ textAlign: 'center' }}>
                    <Spin size='large' tip='Warmup.. 🏋‍' />
                  </Content>
                )}
                {!isAppInitializing && (
                  <>
                    <Route path='/sso' component={Login} />

                    <ProtectedRoute exact path='/' component={Dashboard} />
                    <ProtectedRoute path='/training' component={Training} />
                  </>
                )}
              </Layout>
            </Content>

            <Footer style={{ textAlign: 'center' }}>
              <Typography>© 2022 KERNWERK GmbH | for internal use only</Typography>
            </Footer>
          </Layout>
        </Provider>
      </BrowserRouter>
    )
  }
}
