import { createReducer } from 'reduxsauce'
import update from 'immutability-helper'
import { Types } from '../actions/exercises'

const initialState = {
  collectionLoading: false,
  collection: {},
  collectionCms: {}
}

const loading = (state = initialState) =>
  update(state, {
    collectionLoading: { $set: true }
  })

const exercisesLoaded = (state = initialState, { exercises, exercisesCms }) =>
  update(state, {
    collection: { $set: exercises },
    collectionCms: { $set: exercisesCms },
    collectionLoading: { $set: false }
  })

export default createReducer(initialState, {
  [Types.EXERCISES_LOADED]: exercisesLoaded
})
