import { createActions } from 'reduxsauce'
import http from '../lib/http'

// searchUserByEmail find and returns the user given the email
// in array form.
export const searchUserByEmail = ({ email }) => {
  return http.get(`v1/users?email=${email}`)
}

// searchUserById find and returns the user given user id
// in array form.
export const searchUserById = (uid) => {
  return http.get(`v1/users/${uid}`)
}

export const { Types, Creators } = createActions({
  userChanging: [],
  userChanged: ['user'],
  userReceived: ['user'],
  conditionsLoading: [],
  conditionsLoaded: [],
  conditionsSaved: ['conditions']
})
