import React from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Menu, Layout } from 'antd'
import { Route, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'

import WorkoutCalendar from './workout/Calendar'
import Logtest from './tools/logtest'
import Day from './workout/Day'

const Training = ({ permissions }) => {
  return (
    <>
      <Layout.Sider width={200} style={{ background: '#fff' }}>
        <Menu mode='inline' defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']} style={{ height: '100%' }}>
          <Menu.Item>
            <Link to='/training/calendar'>Workout Kalender</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to='/training/logtest'>Logtest</Link>
          </Menu.Item>
        </Menu>
      </Layout.Sider>

      <Route path='/training/calendar' component={WorkoutCalendar} />
      <Route path='/training/day/:id' component={Day} />
      <Route path='/training/logtest' component={Logtest} />
    </>
  )
}

Training.propTypes = {
  permissions: PropTypes.object.isRequired
}

Training.defaultProps = {}

export default connect((state) => ({ permissions: state.auth.permissions }))(Training)
