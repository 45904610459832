import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { map, get, without, sortBy, find, filter } from 'lodash'
import update from 'immutability-helper'

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined
} from '@ant-design/icons'

import { Form as AntdForm } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Card, Input, Button, Row, Col, Select, InputNumber, Radio, Spin } from 'antd'
import { Field } from 'react-final-form'
import { connect } from 'react-redux'
import { loadExercises } from '../../../actions/exercises'
import http from '../../../lib/http'

const WorkoutEditorKalendar = ({ values, form: { mutators }, refreshExercises, exercises, isBenchmark, exer, loading, gotoCallback }) => {
  if (loading) {
    return <div style={{ 'margin-left': '450px' }}><Spin /></div>
  }

  if (!exer || exer === []) {
    return <></>
  }

  return (
    <div>
      {isBenchmark === true && (
        <Field
          name='training.type'
          render={({ input: { value, onChange } }) => (
            <AntdForm.Item label='Type' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Select onChange={onChange} value={value}>
                <Select.Option value='benchmark'>Benchmark</Select.Option>
                <Select.Option value='strength'>Kraftplan</Select.Option>
              </Select>
            </AntdForm.Item>
          )}
        />
      )}

      {map(get(values, 'training.units', []), (unit, k) => (
        <Card
          size='small'
          title={unit.title ? `${unit.title} - ${unit.uuid}` : 'Unbenannte Unit'}
          key={k}
          style={{ marginBottom: 20 }}
          extra={[
            <Button icon={<ArrowUpOutlined />} key='up' size='small' onClick={() => mutators.changePositionUnitsUp(k)} style={{ marginRight: 10 }} />,
            <Button icon={<ArrowDownOutlined />} key='down' size='small' onClick={() => mutators.changePositionUnitsDown(k)} style={{ marginRight: 10 }} />,
            <Button icon={<DeleteOutlined />} type='danger' size='small' onClick={() => mutators.rmUnit(k)} key='rm' />
          ]}
        >
          <Field
            name={`training.units.${k}.title`}
            render={({ input: { value, onChange } }) => (
              <AntdForm.Item label='Titel' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Input placeholder='Title' value={value} onChange={onChange} />
              </AntdForm.Item>
            )}
          />
          <Field
            name={`training.units.${k}.description`}
            render={({ input: { value, onChange } }) => (
              <AntdForm.Item label='Beschreibung' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Input.TextArea placeholder='Title' value={value} onChange={onChange} />
              </AntdForm.Item>
            )}
          />

          {/* Part loop */}
          {map(get(values, `training.units.${k}.parts`, []), (_, l) => (
            <Card
              style={{ marginBottom: 20 }}
              key={l}
              type='inner'
              size='small'
              extra={[
                <Button
                  icon={<ArrowUpOutlined />}
                  key='up'
                  size='small'
                  onClick={() => mutators.changePositionPartsUp(k, l)}
                  style={{ marginRight: 10 }}
                />,
                <Button
                  icon={<ArrowDownOutlined />}
                  key='down'
                  size='small'
                  onClick={() => mutators.changePositionPartsDown(k, l)}
                  style={{ marginRight: 10 }}
                />
              ]}
            >
              <Row gutter={20}>
                <Col span={14}>
                  <Field
                    name={`training.units.${k}.parts.${l}.mode`}
                    render={({ input: { value, onChange } }) => (
                      <AntdForm.Item label='Modus' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Radio.Group
                          onChange={(e) => {
                            mutators.onModeChanged(k, e.target.value)
                            onChange(e)
                          }}
                          value={value}
                          buttonStyle='solid'
                        >
                          <Radio.Button value='FT'>For Time</Radio.Button>
                          <Radio.Button value='FQ'>For Quality</Radio.Button>
                          <Radio.Button value='AMRAP'>AMRAP</Radio.Button>
                        </Radio.Group>
                      </AntdForm.Item>
                    )}
                  />
                </Col>
                <Col span={5}>
                  <Field
                    name={`training.units.${k}.parts.${l}.modeValue`}
                    render={({ input: { value, onChange } }) => (
                      <AntdForm.Item label='Moduswert' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <InputNumber min={1} value={value} onChange={onChange} />
                      </AntdForm.Item>
                    )}
                  />
                </Col>
                <Col span={5}>
                  <Field
                    name={`training.units.${k}.parts.${l}.modeTimeCap`}
                    render={({ input: { value, onChange } }) => (
                      <AntdForm.Item label='Timecap' required={['FT', 'FQ'].includes(get(values, `training.units.${k}.parts.${l}.mode`))} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <InputNumber
                          min={0}
                          value={value}
                          onChange={onChange}
                          disabled={!['FT', 'FQ'].includes(get(values, `training.units.${k}.parts.${l}.mode`))}
                        />
                      </AntdForm.Item>
                    )}
                  />
                </Col>
              </Row>

              {/* Element loop */}
              {map(get(values, `training.units.${k}.parts.${l}.elements`, []), (_, m) => (
                <div key={m}>
                  {values.training.units[k].parts[l].elements[m].type === 'break' && (
                    <Row gutter={21}>
                      <Col span={22}>
                        <Field
                          name={`training.units.${k}.parts.${l}.elements.${m}.duration`}
                          render={({ input: { value, onChange } }) => (
                            <AntdForm.Item required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                              <InputNumber
                                min={1}
                                max={301}
                                value={value}
                                onChange={onChange}
                                style={{ width: '100%' }}
                                placeholder='Sekunden Pause'
                              />
                            </AntdForm.Item>
                          )}
                        />
                      </Col>

                      <Col span={2}>
                        <AntdForm.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <Button type='danger' icon={<MinusOutlined />} size='small' onClick={() => mutators.rmElement(k, l, m)} />
                        </AntdForm.Item>
                      </Col>
                    </Row>
                  )}

                  {values.training.units[k].parts[l].elements[m].type === 'exercise' && (
                    <Row gutter={21}>
                      <Col span={1}>
                        <Button size='small' style={{ marginTop: 15 }} onClick={() => gotoCallback(values, k, l, m)} key='goto'>→</Button>
                      </Col>
                      <Col span={1}>
                        <Button
                          icon={<ArrowUpOutlined />}
                          key='up'
                          size='small'
                          onClick={() => mutators.changePositionElementsUp(k, l, m)}
                          style={{ marginRight: 10 }}
                        />
                        <Button
                          icon={<ArrowDownOutlined />}
                          key='down'
                          size='small'
                          onClick={() => mutators.changePositionElementsDown(k, l, m)}
                          style={{ marginRight: 10 }}
                        />
                      </Col>

                      <Col span={10}>
                        <AntdForm.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <Field
                            name={`training.units.${k}.parts.${l}.elements.${m}.exercise`}
                            render={({ input: { onChange, value } }) => (
                              <Select
                                ref={(input) => {
                                  if (input) {

                                  }
                                }}
                                placeholder='Übung'
                                value={value}
                                onChange={onChange}
                                showSearch
                                optionFilterProp='title'
                                filterOption={(input, option) =>
                                  option.props.children !== undefined && option.props.children !== null ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false}
                              >
                                {map(filter(sortBy(exer, (e) => { return e.title }), (e) => {
                                  const area = values.training.units[k].parts[l].elements[m].area
                                  if (area === 'alle') {
                                    return e
                                  }
                                  if (area === undefined) {
                                    try {
                                      const _area = getArea(k, l, m)
                                      return e.area.id.toString() === _area
                                    } catch {
                                      return e
                                    }
                                  }
                                  return e.area.id.toString() === values.training.units[k].parts[l].elements[m].area
                                }), (ex) => (
                                  <Select.Option key={ex._id.$oid} value={ex._id.$oid}>
                                    {ex.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          />
                        </AntdForm.Item>
                      </Col>
                      {values.training.units[k].parts[l].elements[m].exercise && (
                        <>
                          <Col span={2}>
                            <Field
                              name={`training.units.${k}.parts.${l}.elements.${m}.amount`}
                              render={({ input: { value, onChange } }) => (
                                <AntdForm.Item required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                  <InputNumber
                                    min={0}
                                    value={value}
                                    onChange={onChange}
                                    placeholder='Reps'
                                    style={{ width: '100%' }}
                                  />
                                </AntdForm.Item>
                              )}
                            />
                          </Col>

                          {isBenchmark === false && (

                            <Col span={3}>
                              <Field
                                name={`training.units.${k}.parts.${l}.elements.${m}.weight_level`}
                                render={({ input: { value, onChange } }) => (
                                  <AntdForm.Item required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                    <Select value={value || 'regular'} disabled onChange={onChange} placeholder='Gewicht'>
                                      <Select.Option value='light'>Leicht</Select.Option>
                                      <Select.Option value='regular'>Normal</Select.Option>
                                      <Select.Option value='heavy'>Schwer</Select.Option>
                                    </Select>
                                  </AntdForm.Item>
                                )}
                              />
                            </Col>
                          )}

                          {isBenchmark === true && (
                            <>
                              <Col span={3}>
                                <Field
                                  name={`training.units.${k}.parts.${l}.elements.${m}.weight_male`}
                                  render={({ input: { value, onChange } }) => (
                                    <AntdForm.Item required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                      <InputNumber
                                        min={0}
                                        onChange={onChange}
                                        value={value}
                                        placeholder='Gewicht male'
                                        style={{ width: '100%' }}
                                        formatter={(val) => `${val} KG`}
                                        parser={(val) => val.replace(' KG', '')}
                                      />
                                    </AntdForm.Item>
                                  )}
                                />
                              </Col>
                              <Col span={3}>
                                <Field
                                  name={`training.units.${k}.parts.${l}.elements.${m}.weight_female`}
                                  render={({ input: { value, onChange } }) => (
                                    <AntdForm.Item required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                      <InputNumber
                                        min={0}
                                        onChange={onChange}
                                        value={value}
                                        placeholder='Gewicht female'
                                        style={{ width: '100%' }}
                                        formatter={(val) => `${val} KG`}
                                        parser={(val) => val.replace(' KG', '')}
                                      />
                                    </AntdForm.Item>
                                  )}
                                />
                              </Col>
                            </>
                          )}
                        </>
                      )}
                      <Col span={2}>
                        <AntdForm.Item>
                          <Button type='danger' icon={<MinusOutlined />} size='small' onClick={() => mutators.rmElement(k, l, m)} />
                        </AntdForm.Item>
                      </Col>
                    </Row>
                  )}
                </div>
              ))}

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  type='dashed'
                  icon={<PlusOutlined />}
                  style={{ marginTop: 20, marginRight: 10 }}
                  onClick={() => mutators.addElement(k, l, 'exercise')}
                >
                  Übung
                </Button>
                <Button type='dashed' icon={<PlusOutlined />} style={{ marginTop: 20 }} onClick={() => mutators.addElement(k, l, 'break')}>
                  Pause
                </Button>
              </div>
            </Card>
          ))}

          <div style={{ textAlign: 'center' }}>
            <Button size='small' type='dashed' icon={<PlusOutlined />} style={{ marginTop: 20 }} onClick={() => mutators.addPart(k)}>
              Part
            </Button>
          </div>
        </Card>
      ))}
      <div style={{ textAlign: 'center' }}>
        <Button size='small' type='dashed' icon={<PlusOutlined />} style={{ marginTop: 20 }} onClick={mutators.addUnit}>
          Unit
        </Button>
      </div>
    </div>
  )
}

WorkoutEditorKalendar.propTypes = {
  values: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  exercises: PropTypes.object.isRequired,
  refreshExercises: PropTypes.func.isRequired,
  isBenchmark: PropTypes.bool,
  gotoCallback: PropTypes.func.isRequired
}
WorkoutEditorKalendar.defaultProps = {
  isBenchmark: false
}

WorkoutEditorKalendar.formMutators = {
  // append a new training unit
  addUnit: (args, state, { changeValue }) => {
    changeValue(state, 'training.units', (val) => {
      if (!val) {
        // add first training unit
        val = [{}]
        return val
      } else {
        // add another training unit
        return update(val, {
          $push: [{}]
        })
      }
    })
  },

  // remove a training unit
  rmUnit: ([index], state, { changeValue }) => {
    changeValue(state, 'training.units', (val) => without(val, val[index]))
  },

  // append a new part
  addPart: ([tuIndex], state, { changeValue }) => {
    changeValue(state, `training.units.${tuIndex}.parts`, (val) => {
      if (!val) {
        // add first training unit
        val = [{ elements: [{ type: 'exercise', weight_level: 'regular', area: 'alle' }] }]
        return val
      } else {
        // add another training unit
        return update(val, {
          $push: [
            {
              elements: [{ type: 'exercise', weight_level: 'regular', area: 'alle' }]
            }
          ]
        })
      }
    })
  },

  // remove a training unit
  rmPart: ([tuIndex, partIndex], state, { changeValue }) => {
    changeValue(state, `training.units.${tuIndex}.parts`, (val) => without(val, val[partIndex]))
  },

  // append a new part
  addElement: ([tuIndex, partIndex, type], state, { changeValue }) => {
    changeValue(state, `training.units.${tuIndex}.parts.${partIndex}.elements`, (val) => {
      if (!val) {
        // add first training unit
        val = [{ type, weight_level: 'regular', area: 'alle' }]
        return val
      } else {
        // add another training unit
        return [...val, { type, weight_level: 'regular', area: 'alle' }]
      }
    })
  },

  rmElement: ([tuIndex, partIndex, elementIndex], state, { changeValue }) => {
    changeValue(state, `training.units.${tuIndex}.parts.${partIndex}.elements`, (val) => without(val, val[elementIndex]))
  },

  onModeChanged: ([tuIndex, partIndex, selectedMode], state, { changeValue }) => {
    if (selectedMode === 'AMRAP') {
      // remove timecap
      changeValue(state, `training.units.${tuIndex}.parts.${partIndex}.modeTimeCap`, () => null)
    }
  },

  changePositionUnitsUp: ([index], state, { changeValue }) => {
    changeValue(state, 'training', (val) => {
      if (index === 0) {
        return val
      } else {
        const bkpUp = val.units[index - 1]
        val.units.splice(index - 1, 1, val.units[index])
        // insert stored item into position `to`

        val.units.splice(index, 1, bkpUp)
        return val
      }
    })
  },

  changePositionUnitsDown: ([index], state, { changeValue }) => {
    changeValue(state, 'training', (val) => {
      if (index === val.size - 1) {
        return val
      } else {
        const bkpDown = val.units[index + 1]
        val.units.splice(index + 1, 1, val.units[index])
        // insert stored item into position `to`
        val.units.splice(index, 1, bkpDown)
        return val
      }
    })
  },

  changePositionPartsUp: ([tuIndex, partIndex], state, { changeValue }) => {
    changeValue(state, `training.units.${tuIndex}`, (val) => {
      if (partIndex === 0) {
        return val
      } else {
        const bkpUp = val.parts[partIndex - 1]
        val.parts.splice(partIndex - 1, 1, val.parts[partIndex])
        // insert stored item into position `to`
        val.parts.splice(partIndex, 1, bkpUp)
        return val
      }
    })
  },

  changePositionPartsDown: ([tuIndex, partIndex], state, { changeValue }) => {
    changeValue(state, `training.units.${tuIndex}`, (val) => {
      if (partIndex === val.parts.length - 1) {
        return val
      } else {
        const bkpDown = val.parts[partIndex + 1]
        val.parts.splice(partIndex + 1, 1, val.parts[partIndex])
        // insert stored item into position `to`
        val.parts.splice(partIndex, 1, bkpDown)
        return val
      }
    })
  },

  changePositionElementsUp: ([tuIndex, partIndex, elementIndex], state, { changeValue }) => {
    changeValue(state, `training.units.${tuIndex}.parts.${partIndex}`, (val) => {
      if (elementIndex === 0) {
        return val
      } else {
        const bkpUp = val.elements[elementIndex - 1]
        val.elements.splice(elementIndex - 1, 1, val.elements[elementIndex])
        // insert stored item into position `to`
        val.elements.splice(elementIndex, 1, bkpUp)
        return val
      }
    })
  },

  changePositionElementsDown: ([tuIndex, partIndex, elementIndex], state, { changeValue }) => {
    changeValue(state, `training.units.${tuIndex}.parts.${partIndex}`, (val) => {
      if (elementIndex === val.elements.length - 1) {
        return val
      } else {
        const bkpDown = val.elements[elementIndex + 1]
        val.elements.splice(elementIndex + 1, 1, val.elements[elementIndex])
        // insert stored item into position `to`
        val.elements.splice(elementIndex, 1, bkpDown)
        return val
      }
    })
  }
}

export default connect(
  (state) => ({
    exercises: state.exercises.collection
  }),
  (dispatch) => ({
    refreshExercises: () => dispatch(loadExercises())
  })
)(WorkoutEditorKalendar)
