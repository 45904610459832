import { Types } from '../actions/auth'
import { createReducer } from 'reduxsauce'
import update from 'immutability-helper'

const initialState = {
  loginLoading: false,
  loginErrorMessage: null,
  currentUser: null,
  roles: [],
  jwt: null,
  permissions: [],

  accessToken: null,
  refreshToken: null
}

export const loginLoading = (state = initialState, action) =>
  update(state, {
    loginLoading: { $set: true },
    loginErrorMessage: { $set: null }
  })

export const loginSuccess = (state = initialState, action) =>
  update(state, {
    loginLoading: { $set: false },
    loginErrorMessage: { $set: null }
  })

export const accessTokenChanged = (state = initialState, { token }) =>
  update(state, {
    accessToken: { $set: token }
  })

export const refreshTokenChanged = (state = initialState, { token }) =>
  update(state, {
    refreshToken: { $set: token }
  })

export const logoutSuccess = (state = initialState, action) => initialState

export const loginError = (state = initialState, action) =>
  update(state, {
    loginLoading: { $set: false },
    loginErrorMessage: { $set: action.errorMessage }
  })

export const userLoaded = (state = initialState, action) =>
  update(state, {
    currentUser: { $set: action.user },
    errorMessage: { $set: null }
  })

export const userChanged = (state = initialState, action) =>
  update(state, {
    currentUser: { $set: action.user }
  })

export const jwtReceived = (state = initialState, payload) =>
  update(state, {
    jwt: { $set: payload.jwt }
  })

export const permissionsReceived = (state = initialState, payload) => update(state, { permissions: { $set: payload.permissions } })

const handlers = {
  [Types.USER_LOADED]: userLoaded,
  [Types.USER_CHANGED]: userChanged,
  [Types.JWT_RECEIVED]: jwtReceived,
  [Types.LOGIN_LOADING]: loginLoading,
  [Types.LOGIN_ERROR]: loginError,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.REFRESH_TOKEN_CHANGED]: refreshTokenChanged,
  [Types.ACCESS_TOKEN_CHANGED]: accessTokenChanged,
  [Types.PERMISSIONS_RECEIVED]: permissionsReceived
}

export default createReducer(initialState, handlers)
