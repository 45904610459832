import {forEach} from 'lodash';

function validateBreaks(values) {
    let isInvalid = false;
    forEach(values.training.units, (vtu) => {

	if (vtu.parts.length > 0) {
	    forEach(vtu.parts, (part) => {
		if (part.elements.length > 0 ) {
		    forEach(part.elements, (element) => {
			console.log(element);
			if (element.type === "break") {
			    console.log("item type is pause:", element);
			    if (element.duration > 300 || element.duration <1) {
				isInvalid = true;
				return;
			    }
			}
		    });
		}
	    });
	}
    });

    return isInvalid;
}

export default validateBreaks;
