import { createActions } from 'reduxsauce'
import { message } from 'antd'
import http from '../lib/http'

export const { Creators, Types } = createActions({
  exercisesLoading: [],
  exercisesLoaded: ['exercises', 'exercisesCms']
})

export const loadExercises = () => async (dispatch) => {
  dispatch(Creators.exercisesLoading())

  const { data } = await http.get('v1/exercises')

  const exercises = {}
  data.forEach((doc) => {
    exercises[doc._id.$oid] = doc
  })

  dispatch(Creators.exercisesLoaded(exercises, {}))
}

export const saveExercise = (id, values) => async (dispatch) => {
  try {
    await http.put(`admin/exercises/entries/${id}`, values)
  } catch (e) {
    console.warn(e)
    message.error('Übung konnte nicht gespeichert werden!')
  }
}

export const deleteExercise = (id) => async (dispatch) => {
  try {
    await http.delete(`admin/exercises/entries/${id}`)
    // update local storage
    await dispatch(loadExercises())
  } catch (e) {
    console.warn(e)
    message.error('Übung konnte nicht gelöscht werden!')
  }
}
