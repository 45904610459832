import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { EllipsisOutlined, PrinterOutlined, SaveOutlined } from '@ant-design/icons'
import { Form as AntdForm } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import {
  Layout,
  Button,
  PageHeader,
  message,
  Tabs,
  Select,
  Switch,
  Typography,
  Descriptions,
  Input,
  DatePicker,
  Tag,
  Popconfirm,
  Collapse,
  List
} from 'antd'
import { Form, Field } from 'react-final-form'
import { map, find, forEach, filter } from 'lodash'
import http from '../../../../lib/http'
import WorkoutEditorKalendar from '../../../../components/training/WorkoutEditorKalendar'
import { Link } from 'react-router-dom'
import moment from 'moment'
import validateBreaks from '../../../../helpers/BreakValidator'
import hasValidModeTimes from '../../../../helpers/ModeTimesValidator'
const { Panel } = Collapse

const Day = ({ match, history }) => {
  const [state, setState] = useState({ loading: true, workout: null, trainingDay: null })
  const [exercises, setExercises] = useState([])

  useEffect(() => {
    const loadExerciseData = async () => {
      const { data } = await http.get('v1/exercises')
      const kv = {}
      forEach(data, (k, _) => {
        kv[k._id.$oid] = k
      })

      setExercises({ ...kv })
    }

    loadExerciseData()
  }, [])

  useEffect(() => {
    const preloadWod = async () => {
      const { data: woc } = await http.get(`v1/calendar/${match.params.id}`)
      const { data } = await http.get(`v1/workout/${woc.workout_id.$oid}`)
      setState({ workout: data, loading: false, trainingDay: woc })
    }

    preloadWod()
  }, [match])

  const doSave = useCallback(
    async (values) => {
      const isInvalid = validateBreaks(values)
      if (isInvalid === true) {
        message.error('Pause muss zwischen 1 und 300 sein')
        return
      }

      if (hasValidModeTimes(values) === false) {
        message.error('Moduswert oder TimeCap ist leer/ungültig')
        return
      };

      if (values.meta.internal_title === undefined) {
        values.meta.internal_title = ''
      }

      map(values.training.units, (du, k) => {
        map(du.parts, (dp, l) => {
          map(dp.elements, (de, m) => {
            const item = values.training.units[k].parts[l].elements[m]
            if (item.area !== undefined) {
              delete values.training.units[k].parts[l].elements[m].area
            }
          })
        })
      })

      const msgRef = message.loading('Wird gespeichert..', 0)

      // try to save workout in hildegard
      try {
        // check if we need to update or create the training
        const { data: hildegardData } = await http.put(`v1/workout/${state.workout._id.$oid}`, { ...values.training, type: 'wod' })

        console.log('state meta:', {
          ...values.meta,
          is_approved: values.meta.is_approved
        })
        // create
        const { data } = await http.put(`v1/calendar/${match.params.id}`, {
          ...values.meta,
          is_approved: values.meta.is_approved
        })

        if (values.cont === 'saveAndCont') {
          message.success('Das Training wurde gespeichert und fortgesetzt.')
        } else {
          message.success('Training wurde gespeichert.')

          history.push('/training/calendar')
        }
      } catch (e) {
        console.warn(e)

        message.error('Training konnte nicht gespeichert werden.')
      }

      msgRef()
    },
    [state]
  )

  const gotoExercise = (values, unit, part, element) => {
    try {
      let exercise
      exercise = values.training.units[unit].parts[part].elements[element]
      if (exercise !== undefined) {
        if (exercise.exercise !== undefined) {
          const _window = window.open(`/training/exercises/${exercise.exercise}/edit`, '_blank')
          _window.focus()
        }
      }
    } catch (e) {
      console.log('>> unable to push to target location.', e)
    }
  }

  if (state.loading === true) {
    return <></>
  }

  const dateShow = () => {
    return moment(state.trainingDay.date).format('LL')
  }

  return (
    <Layout.Content style={{ padding: '0 50px' }}>
      <Form
        onSubmit={doSave}
        initialValues={{ training: state.workout, meta: state.trainingDay }}
        mutators={WorkoutEditorKalendar.formMutators}
        render={({ values, handleSubmit, form }) => (
          <AntdForm onSubmit={handleSubmit}>
            <PageHeader
              title='Training anlegen'
              subTitle={dateShow()}
              onBack={() => {
                history.push('/training/calendar')
              }}
              extra={[
                <Button type='primary' htmlType='submit' icon={<SaveOutlined />} key='save'>
                  Speichern & Zurück
                </Button>,
                <Button
                  type='primary'
                  htmlType='submit'
                  onClick={() => {
                    form.change('cont', 'saveAndCont')
                  }}
                  icon={<EllipsisOutlined />}
                  key='saveAndContinue'
                >
                  Zwischenspeichern
                </Button>
              ]}
            />

            <Tabs>
              <Tabs.TabPane tab='Trainingstag' key='trainingday'>
                <Field
                  name='meta.is_approved'
                  render={({ input: { onChange, value } }) => (
                    <AntdForm.Item label='Approved' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                      <Switch checked={!!value} onChange={onChange} />
                    </AntdForm.Item>
                  )}
                />

                <Field
                  name='meta.internal_title'
                  render={({ input: { value, onChange } }) => (
                    <AntdForm.Item label='Interner Titel' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                      <Input placeholder='Interner Titel' value={value} onChange={onChange} />
                    </AntdForm.Item>
                  )}
                />

              </Tabs.TabPane>
              <Tabs.TabPane tab='WOD Editor' key='wodbuilder'>
                {exercises !== [] && <WorkoutEditorKalendar form={form} values={values} exer={exercises} loading={exercises.length === 0} />}
              </Tabs.TabPane>
              <Tabs.TabPane tab='Debug' key='debug'>
                <Descriptions title='Debug' bordered column={1}>
                  {state.trainingDay.workout_id && (
                    <>
                      <Descriptions.Item label='Workout ID' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Typography.Text copyable>{state.trainingDay.workout_id['$oid']}</Typography.Text>

                        <Link to={`/training/logtest?workoutId=${state.trainingDay.workout_id['$oid']}`}>
                          <Button>Aufrufen</Button>
                        </Link>
                      </Descriptions.Item>
                    </>
                  )}
                </Descriptions>
              </Tabs.TabPane>
            </Tabs>
          </AntdForm>
        )}
      />
    </Layout.Content>
  )
}

Day.propTypes = {
  match: PropTypes.object.isRequired,
  exercises: PropTypes.object.isRequired,
  refreshExercises: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}
Day.defaultProps = {}

export default Day
