import { combineReducers } from 'redux'
import auth from './auth'
import user from './user'
import exercises from './exercises'

export default combineReducers({
  auth,
  user,
  exercises
})
