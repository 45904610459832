import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Avatar } from 'antd';
import ExerciseAssetUri from '../../../helpers/ExerciseAssetUri';

function getTitle(seconds) {
    return `${seconds}s Pause`;
}

const Exercise = ({ element, exerciseDb }) => {
    const Item = (props) => {
        if(props.title) return (<strong>{props.title}</strong>);
    }
    const exercise = exerciseDb[element.exercise];
    if (exercise == undefined) {
        return (
            <List.Item>
                {element.type !== 'exercise' && <Item title={getTitle(element.duration)}></Item>}
            </List.Item>
        )
    }
    return (
        <List.Item>
            <List.Item.Meta
                title={
                    <>
                        {element.amount? element.amount: element.amount === 0 ? 'MAX': ''}
                        {exercise && exercise.unit && exercise.unit !== 'reps' && exercise.unit.charAt(0)} <Item title={exercise && exercise.title?exercise.title:''}></Item>
                        {element.type !== 'exercise' && <Item title={getTitle(element.duration)}></Item>}
                    </>
                }
                avatar={
                    exercise && exercise.loopVideoId ? (
                        <Avatar src={ExerciseAssetUri.getThumbnailUri(exercise)} alt={exercise.title} shape="square" />
                    ) : (
                        <Avatar shape="square">n/a</Avatar>
                    )
                }
            />

            <div>{element.weight ? `${element.weight} kg` : ''}</div>
        </List.Item>
    );
};

Exercise.propTypes = {
    element: PropTypes.object.isRequired,
    exerciseDb: PropTypes.object.isRequired,
};

export default connect((state) => ({
    exerciseDb: state.exercises.collection,
}))(Exercise);
