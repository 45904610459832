import React from 'react'
import update from 'immutability-helper'
import { createReducer } from 'reduxsauce'
import { Types } from '../actions/user'
import { Types as AuthTypes } from '../actions/auth'

const defaultState = {
  data: {},
  conditionsLoading: false
}

const userChanged = (state = defaultState, action) =>
  update(state, {
    data: { $set: action.user }
  })

const userReceived = (state = defaultState, action) =>
  update(state, {
    data: { $set: action.user }
  })

const conditionsSaved = (state = defaultState, action) =>
  update(state, {
    data: {
      conditions: { $merge: action.conditions }
    }
  })

const conditionsLoading = (state = defaultState, action) =>
  update(state, {
    conditionsLoading: { $set: true }
  })

const conditionsLoaded = (state = defaultState, action) =>
  update(state, {
    conditionsLoading: { $set: false }
  })

const loggedOut = (state = defaultState, action) => defaultState

export default createReducer(defaultState, {
  [Types.USER_CHANGED]: userChanged,
  [Types.USER_RECEIVED]: userReceived,
  [Types.CONDITIONS_LOADING]: conditionsLoading,
  [Types.CONDITIONS_LOADED]: conditionsLoaded,
  [Types.CONDITIONS_SAVED]: conditionsSaved,
  [AuthTypes.LOGOUT_SUCCESS]: loggedOut
})
