import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Layout, Calendar, Spin, message, Alert, PageHeader, Typography, Tag, Badge, Divider, Button } from 'antd'
import { transform, map, get, isEmpty, forEach, has } from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import http from '../../../../lib/http'

const WorkoutPreview = connect((state) => ({
  exercises: state.exercises.collection
}))(({ workoutId, isApproved, textsWritten, canHaveMark, isInPast, internalTitle, exercises, workoutObj, date }) => {
  const [workout, setWorkout] = useState(null)

  useEffect(() => {
    (async () => {
      const { data } = await http.get(`v1/workout/${workoutId}`)

      setWorkout(data)
    })()
  }, [workoutId])

  const onDateClick = useCallback(async (date) => {
    const msgRef = message.loading('Einen Moment..', 0)

    // TODO implement prefill form and prevent deleting wokrout ID on click from calendar entry

    const formattedDate = date.format('YYYY-MM-DD')

    // check if entry is existing
    try {
      const { data: day } = await http.get(`v1/calendar/${formattedDate}`)
      // redirect to already existing day
      window.location = `/training/day/${day.date}`
    } catch (e) {
      try {
        // find and/or update day entry
        const { data: createdDay } = await http.post(`v1/calendar/${formattedDate}`)
        window.location = `/training/day/${createdDay.date}`
      } catch (e) {
        console.warn(e)
        message.error('Eintrag konnte nicht erzeugt werden.')
      }
    }

    msgRef()
    // refreshCalendar();
  }, [])

  if (!workout) {
    return <center><Spin /></center>
  }
  return (
    <div style={{ height: '200px' }} onClick={(event) => onDateClick(date)}>
      {isApproved &&
        <Tag color='green'>WOD</Tag>}
      {!isApproved && isInPast &&
        <Tag color='#ffc53d'>Nicht Genehmigt</Tag>}
      {!isApproved && !isInPast &&
        <Tag color='red'>Überfällig</Tag>}
      <Divider />
      <Typography.Text strong> {internalTitle !== '' && `Interner Titel: ${internalTitle}`}</Typography.Text>
      {map(get(workout, 'units'), (unit) => (
        <div key={unit.uuid}>
          {map(get(unit, 'parts'), (part, i) => (
            <div key={i}>
              <Typography.Text strong>
                {part.modeTimeCap && part.mode === 'FQ' ? `${part.modeValue} ROUNDS FQ` : part.mode === 'FT' ? `${part.modeValue} ROUNDS FT` : ''}
                {!part.modeTimeCap && `${part.mode} ${part.modeValue}`}
              </Typography.Text>
              <div>
                <Typography.Text small>
                  {part.modeTimeCap && `(TC ${part.modeTimeCap})`}
                </Typography.Text>
              </div>

              <ul style={{ 'list-style-type': 'none', padding: 0, 'margin-top': 8 }}>
                {map(get(part, 'elements'), (element, k) => {
                  switch (element.type) {
                    case 'break':
                      if (element.duration === undefined) {
                        return <li>Pause</li>
                      } else {
                        return <li>{element.duration} Sek. Pause</li>
                      }
                    case 'exercise':
                      // eslint-disable-next-line no-case-declarations
                      const exercise = get(exercises, element.exercise, null)
                      if (exercise === null) {
                        return <li><Spin /></li>
                      }

                      return <li style={{ 'padding-top': '3px' }}><Typography.Text strong>{`${element.amount === 0 ? 'MAX' : element.amount} `}</Typography.Text>{exercise.title}</li>
                  }
                })}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
})

WorkoutPreview.propTypes = {
  workoutId: PropTypes.string.isRequired,
  isApproved: PropTypes.bool.isRequired,
  textsWritten: PropTypes.bool.isRequired,
  canHaveMark: PropTypes.bool.isRequired,
  isInPast: PropTypes.bool.isRequired,
  internalTitle: PropTypes.string.isRequired,
  workoutObj: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired
}

const WorkoutCalendar = () => {
  const [workoutCalendar, setWorkoutCalendar] = useState({})
  const [isRefreshing, setIsRefreshing] = useState(false)

  const refreshCalendar = useCallback(async () => {
    setIsRefreshing(true)

    try {
      const { data: workoutCalendar } = await http.get('v1/calendar')
      const transformedCalendar = transform(workoutCalendar, (res, curr) => {
        res[curr.date] = curr
      })
      setWorkoutCalendar(transformedCalendar)
    } catch (e) {
      message.error('Kalender konnte nicht geladen werden.')
    }

    setIsRefreshing(false)
  }, [])

  useEffect(() => {
    refreshCalendar()
  }, [])

  // render cell
  const renderCell = useCallback(
    (date) => {
      const workoutToday = workoutCalendar[date.format('YYYY-MM-DD')]
      const ddiff = moment().diff(date.format('YYYY-MM-DD'), 'days')
      const isInPast = ddiff > 0
      const canHaveMark = !!(ddiff === 0 || (ddiff <= 0 && ddiff >= -21))

      if (workoutToday === undefined || (workoutToday.workout_id === null || workoutToday.workout_id === undefined)) {
        if (!canHaveMark) {
          return <><div onClick={(event) => onDateClick(date)}><Tag color='#ffc53d'>Nicht Genehmigt</Tag><Tag color='#bfbfbf' style={{ 'margin-top': '5px' }}>Kein Workout</Tag></div></>
        }
        return <><div onClick={(event) => onDateClick(date)}><Tag color='red'>Überfällig</Tag><Tag color='#bfbfbf' style={{ 'margin-top': '5px' }}>Kein Workout</Tag></div></>
      }

      const internal_title = (workoutToday.internal_title === undefined || workoutToday.internal_title === null) ? '' : workoutToday.internal_title

      return <WorkoutPreview workoutId={workoutToday.workout_id.$oid} isApproved={workoutToday.is_approved} canHaveMark={canHaveMark} isInPast={isInPast} date={date} internalTitle={internal_title} />
    },
    [workoutCalendar]
  )

  // click on date
  const onDateClick = useCallback(async (date) => {
    const msgRef = message.loading('Einen Moment..', 0)

    // TODO implement prefill form and prevent deleting wokrout ID on click from calendar entry

    const formattedDate = date.format('YYYY-MM-DD')

    // check if entry is existing
    try {
      const { data: day } = await http.get(`v1/calendar/${formattedDate}`)
      // redirect to already existing day
      window.location = `/training/day/${day.date}`
    } catch (e) {
      // not yet existing
      try {
        // find and/or update day entry
        const { data: createdDay } = await http.post(`v1/calendar/${formattedDate}`)
          window.location = `/training/day/${createdDay.date}`;
      } catch (e) {
        console.warn(e)
        message.error('Eintrag konnte nicht erzeugt werden.')
      }
    }

    msgRef()
    // refreshCalendar();
  }, [])

  return (
    <Layout.Content>
      <PageHeader title='Workout Kalender' />
      <Spin spinning={isRefreshing}>
        <Calendar dateCellRender={renderCell} validRange={[moment('20200101', 'YYYYMMDD'), moment().add(1, 'year')]} />
      </Spin>
    </Layout.Content>
  )
}

WorkoutCalendar.propTypes = {}
WorkoutCalendar.defaultProps = {}

export default WorkoutCalendar
