import { createActions } from 'reduxsauce'
import http from '../lib/http'
import update from 'immutability-helper'
import { loadExercises } from './exercises'

export const { Types, Creators } = createActions(
  {
    loginLoading: [],
    loginError: ['errorMessage'],
    loginSuccess: [],
    logoutSuccess: [],

    refreshTokenChanged: ['token'],
    accessTokenChanged: ['token'],

    userLoaded: ['user'],
    userChanged: ['user'],
    rolesReceived: ['roles'],

    jwtReceived: ['jwt'],
    permissionsReceived: ['permissions']
  },
  {
    prefix: 'AUTH_'
  }
)

export const refreshAccessToken = () => async (dispatch, getState) => {
  console.log('state', getState())

  const {
    auth: {
      refreshToken
    }
  } = getState()

  const token = refreshToken

  console.log('[refreshAccessToken] Refreshing token.', token)

  try {
    // store the new tokens
    localStorage.setItem('auth:refresh-token', refreshToken)

    // push new tokens to redux store
    dispatch(Creators.accessTokenChanged(refreshToken))
    dispatch(Creators.refreshTokenChanged(refreshToken))

    // append new token to all http requests
    http.interceptors.request.use((config) =>
      update(config, {
        headers: {
          $merge: {
            Authorization: `Bearer ${refreshToken}`
          }
        }
      })
    )

    dispatch(Creators.permissionsReceived([]))

    return refreshToken
  } catch (e) {
    console.warn(e)

    if (!e.response) {
      console.warn('no internet connection')
    }

    if (e.response.status === 403 || e.response.status === 404) {
      console.warn('token invalid or user deleted')
      dispatch(signOut())
    }
  }
}

/**
 * Must be called once after successful authentication
 */
export const boot = () => async (dispatch) => {
  // get tokens from async storage
  const refreshToken = await localStorage.getItem('auth:refresh-token', null)
  if (refreshToken !== null) {
    // push refresh token to state
    dispatch(Creators.refreshTokenChanged(refreshToken))

    // request an access token

    // -- Prepare internal auth system -- //
    await dispatch(refreshAccessToken())
    // successfully authenticated
    dispatch(Creators.loginSuccess())

    // preload exercises
    dispatch(loadExercises())
  }
}

export const login = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch(Creators.loginLoading())

      const {
        data: { token }
      } = await http.post('v1/login', { email, password })

      // store refresh token in async storage
      await localStorage.setItem('auth:refresh-token', token)
      await dispatch(boot())
      window.location.reload()
    } catch (e) {
      return dispatch(Creators.loginError(e.message))
    }
  }
}

export const signOut = () => async (dispatch) => {
  await localStorage.clear()
  dispatch(Creators.logoutSuccess())
  window.location.reload()
}

// getAccessToken returns locally preserved refresh-token.
export const getAccessToken = () => {
  const refreshToken = localStorage.getItem('auth:refresh-token', null)
  return refreshToken
}
