import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Typography,
  PageHeader,
  Button,
  Row,
  Input,
  Collapse,
  Col,
  Descriptions,
  Radio,
  message,
  Tabs,
  Spin,
  InputNumber as AntdInputNumber,
  Checkbox,
  Select
} from 'antd'
import { Form, Field } from 'react-final-form'
import _ from 'lodash'
import InputNumber from '../../../components/form/InputNumber'
import http from '../../../lib/http'
import hildegard, { goHildegard } from '../../../lib/hildegard'
import { connect } from 'react-redux'
import Workout from '../../../components/training/workout'
import Log from './log'
import moment from 'moment'

const Logtest = ({ exerciseDb, currentUser }) => {
  const [workoutId, setWorkoutId] = useState(null)
  const [hildegardEnv, setHildegardEnv] = useState('hgo')
  const [trainingSet, settrainingSet] = useState(null)
  const [workout, setWorkout] = useState(null)
  const [log, setLog] = useState(null)
  const [requirements, setRequirements] = useState()
  const [equipments, setEquipments] = useState()
  const [targetUserId, setTargetUserId] = useState(currentUser.profile.data.user_id)
  const [training, setTraining] = useState()
  const [exercises, setExercises] = useState([])
  const [meta, setMeta] = useState({})

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    const loadExerciseData = async () => {
      const { data } = await http.get('v1/exercises')
      const kv = {}
      const _data = _.filter(Object.assign({}, data), (d) => {
        if (kv[d.id] !== undefined) {
          return false
        }
        return true
      })
      setExercises({ ..._data })
    }

    loadExerciseData()
  }, [])

  const doSearchUser = useCallback(async (userId) => {
    try {
      // const { data } = await http.get(`users/${userId}/training-set?format=raw`);
      // console.debug(data);
      // const { data: equipmentsData } = await http.get('v1/equipments');

      setEquipments({})

      const kv = {}
      _.forEach(exerciseDb, (k, v) => {
        kv[v] = { ieri: 60 }
      })

      settrainingSet({ conditionSet: { equipments: {}, requirements: {} }, exerciseSet: kv, user: { age: 30, height: 190, weight: 80 } })
      setTargetUserId('')

      const { data: woc } = await http.get(`v1/workout/${workoutId}`)
      console.log('training', woc)
      setTraining(woc)
    } catch (e) {
      console.log(e)
      message.warn('User nicht gefunden')
    }
  }, [exerciseDb, workoutId])

  const doGenerateLogtest = useCallback(
    async (values) => {
      try {
        for (const [equipment, weights] of Object.entries(values.trainingSet.conditionSet.equipments)) {
          values.trainingSet.conditionSet.equipments[equipment] = _.map(weights, (w) => parseFloat(w))
        }
      } catch {
      }

      values.settings = values.trainingSet

      let generatedWorkout
      let targetLog
      try {
        console.log('hildegardEnv:', hildegardEnv)
        if (values.trainingSet.user !== undefined) {
          values.trainingSet.user.gender = 1
        }
        const { data } = await http.post('v1/users/none/daily-workout?log=true', values)
        data.units = data.training.units
        console.log('data')
        generatedWorkout = data

        console.log('>> WOD generated', generatedWorkout)

        setWorkout(data)
        targetLog = generatedWorkout.log
        message.success('Workout wurde generiert')
      } catch (e) {
        message.error('Workout konnte nicht abgefragt werden', e)
        console.log(e.response)
      }
      const loadingMsg = message.loading('Log wird geholt', 0)
      try {
        const { data: log } = await http.get(`v1/log/${targetLog}?stage=${hildegardEnv === 'hgo' ? 'false' : 'true'}`)
        const result = []
        _.forEach(log.entries, (l) => {
          result.push({
            level: l.level,
            message: l.message,
            meta: {
              _file: l.file,
              file: l.name + ' ',
              line: l.line,
              name: l.name,
              function: l.function,
              time: l.time,
              class: l.function
            }
          })
        })
        const r = { date: log.date, entries: result }
        setLog(r)
        message.success('Log wurde abgefragt')
      } catch (e) {
        console.warn(e)
        message.error('Log konnte nicht geladen werden')
      }
      loadingMsg()
    },
    [hildegardEnv, workoutId]
  )

  const reset = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search)

    setWorkout(null)
    setLog(null)
    settrainingSet(null)

    if (urlParams.get('userId')) {
      setTargetUserId(urlParams.get('userId'))
    }

    if (urlParams.get('workoutId')) {
      setWorkoutId(urlParams.get('workoutId'))
    } else {
      try {
        // get today's workout ID
        const formattedDate = moment().format('YYYY-MM-DD')
        const { data: day } = await http.get(`v1/calendar/${formattedDate}`)
        if (!_.isEmpty(day.workout_id.$oid)) {
          setWorkoutId(day.workout_id.$oid)
        } else {
          message.warn('Kein Workout für heute geplant.')
        }
      } catch (e) {
        setWorkoutId('')
        message.error('Workout Preview konnte nicht abgerufen werden.')
      }
    }
    const { data: requirementsData } = await http.get('v1/requirements')
    _.forEach(requirementsData, (k, v) => {
      requirementsData[v].id = k._id.$oid
    })
    setRequirements(_.filter(requirementsData, (r) => [''].includes(r.type)))
  }, [])

  const selectAllRequirements = (e) => {
    const reqs = {}
    _.map(requirements, (r, i) => {
      if (r.id === '5e45a5eb2f77bbeafd59c313') {
        if (e.target.checked) {
          reqs[r.id] = false
        } else {
          reqs[r.id] = true
        }
      } else if (r.id === '5e45a5fe2f77bbeafd59d3a4') {
        if (e.target.checked) {
          reqs[r.id] = false
        } else {
          reqs[r.id] = true
        }
      } else {
        reqs[r.id] = !!e.target.checked
      }
    })
    settrainingSet({ ...trainingSet, conditionSet: { ...trainingSet.conditionSet, requirements: { ...trainingSet.conditionSet.requirements, ...reqs } } })
  }

  const selectAllEquipments = (e) => {
    const equips = {}
    _.map(equipments, (eq, i) => {
      if (e.target.checked) {
        equips[eq.id] = eq.weights
      } else {
        equips[eq.id] = []
      }
    })
    settrainingSet({ ...trainingSet, conditionSet: { ...trainingSet.conditionSet, equipments: equips } })
  }

  const selectAllBasicConditions = (e) => {
    if (e.target.checked) {
      settrainingSet({ ...trainingSet, conditionSet: { ...trainingSet.conditionSet, requirements: { ...trainingSet.conditionSet.requirements, '5e45a5eb2f77bbeafd59c313': false, '5e45a5fe2f77bbeafd59d3a4': false } } })
    } else {
      settrainingSet({ ...trainingSet, conditionSet: { ...trainingSet.conditionSet, requirements: { ...trainingSet.conditionSet.requirements, '5e45a5eb2f77bbeafd59c313': true, '5e45a5fe2f77bbeafd59d3a4': true } } })
    }
  }

  if (!currentUser || !exerciseDb) {
    return <div />
  }

  return (
    <Layout.Content style={{ padding: '0 50px' }}>
      <PageHeader
        title='Logtest'
        extra={
                    trainingSet !== null || workout !== null
                      ? [
                        <Button type='default' key='reset' onClick={reset}>
                          Reset
                        </Button>
                        ]
                      : null
                }
      />

      {true && (
        <Row>
          <Col span={8}>
            <Input.Search
              addonBefore='User ID:'
              value={targetUserId}
              placeholder='User ID'
              onSearch={doSearchUser}
              onChange={(e) => { setTargetUserId(e.target.value) }}
              style={{ marginBottom: 20 }}
              key='search'
            />
          </Col>
          <Col span={8} offset={1}>
            <Spin spinning={workoutId === null}>
              <Input addonBefore='Workout ID' value={workoutId} onChange={(e) => setWorkoutId(e.target.value)} />
            </Spin>
          </Col>
          <Col span={6} offset={1} style={{ alignItems: 'flex-end' }}>
            <Radio.Group key='env' defaultValue='hgo' onChange={(e) => setHildegardEnv(e.target.value)}>
              <Radio.Button value='hgo'>Prod</Radio.Button>
              <Radio.Button value='stage'>Staging</Radio.Button>
            </Radio.Group>
            <Button type='primary' key='load' onClick={() => { doSearchUser(targetUserId) }} style={{ marginLeft: '12px' }}>
              Laden
            </Button>
          </Col>
        </Row>
      )}

      {/* Customize Training Set */}
      {trainingSet !== null && (
        <Form
          initialValues={{ trainingSet: trainingSet, training: training, meta: meta }}
          onSubmit={doGenerateLogtest}
          render={({ handleSubmit, values, form }) => (
            <>

              <Collapse defaultActiveKey={['1', '3']}>
                <Collapse.Panel header='Profil' key='1'>
                  <Row>
                    <Col span={8}>
                      <Field label='Alter' name='trainingSet.user.age' component={InputNumber} />
                    </Col>
                    <Col span={8}>
                      <Field label='Größe (cm)' format={(val) => `${val} cm`} name='trainingSet.user.height' component={InputNumber} />
                    </Col>
                    <Col span={8}>
                      <Field label='Gewicht (kg)' format={(val) => `${val} kg`} name='trainingSet.user.weight' component={InputNumber} />
                    </Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel header='User-IERIs' key='2'>
                  <Descriptions size='small' bordered>
                    {_.map(trainingSet.exerciseSet, (ex, id) => {
                      const exercise = _.get(exerciseDb, id, null)

                      if (!exercise) {
                        return <></>
                      }

                      return (
                        <Descriptions.Item key={id} label={exercise.title}>
                          <Field
                            name={`trainingSet.exerciseSet.${id}.ieri`}
                            render={({ input: { value, onChange } }) => (
                              <AntdInputNumber value={value} onChange={onChange} min={1} size='small' style={{ width: '100%' }} />
                            )}
                          />
                        </Descriptions.Item>
                      )
                    })}
                  </Descriptions>
                </Collapse.Panel>
                <Collapse.Panel header='Anpassungen' key='3'>
                  <Typography.Paragraph>Workoutlänge</Typography.Paragraph>
                  <Field
                    name='trainingSet.customization.duration'
                    buttonStyle='solid'
                    defaultValue='regular'
                    render={({ input: { onChange, value } }) => (
                      <Radio.Group value={value} onChange={onChange}>
                        <Radio.Button value='short'>Short</Radio.Button>
                        <Radio.Button value='medium'>Medium</Radio.Button>
                        <Radio.Button value='regular'>Regular</Radio.Button>
                        <Radio.Button value='long'>Long</Radio.Button>
                      </Radio.Group>
                    )}
                  />
                </Collapse.Panel>
                <Collapse.Panel header='Möglichkeiten (Ausstattung)' key='4'>
                  <Descriptions size='small' bordered>
                    {_.map(requirements, (r, i) => (
                      <Descriptions.Item label={r.title} key={i}>
                        <Field
                          name={`trainingSet.conditionSet.requirements.${r.id}`}
                          render={({ input: { value, onChange } }) => (
                            <Checkbox type='checkbox' checked={value === true} value onChange={onChange} />
                          )}
                        />
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                  <Checkbox onChange={selectAllRequirements}>Alle</Checkbox>
                </Collapse.Panel>

                <Collapse.Panel header='Grundbedingungen' key='5'>
                  <Descriptions size='small' bordered>
                    <Descriptions.Item label='Sitzen ausschließen'>
                      <Field
                        name='trainingSet.conditionSet.requirements.5e45a5eb2f77bbeafd59c313'
                        render={({ input: { value, onChange } }) => (
                          <Checkbox
                            type='checkbox'
                            checked={value === false}
                            onChange={() => {
                              onChange(!value)
                            }}
                          />
                        )}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label='Springen ausschließen'>
                      <Field
                        name='trainingSet.conditionSet.requirements.5e45a5fe2f77bbeafd59d3a4'
                        render={({ input: { value, onChange } }) => (
                          <Checkbox
                            type='checkbox'
                            checked={value === false}
                            onChange={() => {
                              onChange(!value)
                            }}
                          />
                        )}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                  <Checkbox onChange={selectAllBasicConditions}>Alle</Checkbox>
                </Collapse.Panel>

                <Collapse.Panel header='Equipments' key='6'>
                  <Descriptions size='small' bordered>
                    {_.map(equipments, (equip, i) => (
                      <Descriptions.Item label={equip.title} key={i}>
                        <Field
                          name={`trainingSet.conditionSet.equipments.${equip.id}`}
                          render={({ input: { value, onChange } }) => (
                            <Select mode='tags' value={value === '' ? undefined : value} onChange={onChange} style={{ width: '100%' }}>
                              {_.map(equip.weights, (w) => (
                                <Select.Option value={String(w)}>{`${w} kg`}</Select.Option>
                              ))}
                            </Select>
                          )}
                        />
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                  <Checkbox onChange={selectAllEquipments}>Alle</Checkbox>
                </Collapse.Panel>
              </Collapse>

              <div style={{ marginTop: 20 }}>
                <Button type='primary' onClick={handleSubmit}>
                  Workout generieren
                </Button>
              </div>
            </>
          )}
        />
      )}

      <Tabs defaultActiveKey='workout' animated={false}>
        {workout !== null && (
          <Tabs.TabPane key='workout' tab='Workout'>
            <Workout workout={workout} userId={targetUserId} />
          </Tabs.TabPane>
        )}
        {log !== null && (
          <Tabs.TabPane key='log' tab='Log' disabled={log === null}>
            <Log log={log} />
          </Tabs.TabPane>
        )}
      </Tabs>

    </Layout.Content>
  )
}

Logtest.propTypes = {
  exerciseDb: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
}
Logtest.defaultProps = {}

export default connect((state) => ({
  exerciseDb: state.exercises.collection,
  currentUser: state.auth.currentUser
}))(Logtest)
