import axios from 'axios';

// TODO(): change this
// const apiUrl = 'http://localhost:7777/';
const apiUrl = 'https://api.paragym.de/';

const http = axios.create({
    baseURL: apiUrl,
    timeout: 40000,
});

http.interceptors.response.use(
    (res) => res,
    (err) => {
        return Promise.reject(err);
    },
);

export default http;
