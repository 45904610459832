import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Link } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import Logo from '../../assets/img/logo_wo_claim_white.png'
import { signOut } from '../../actions/auth'

const Header = ({ isAppInitializing, logout, currentUser, permissions }) => {
    return ("")
}

Header.propTypes = {
  permissions: PropTypes.object.isRequired
}

Header.defaultProps = {}

export default connect(
  (state) => ({
    currentUser: state.auth.currentUser,
    permissions: state.auth.permissions
  }),
  (dispatch) => ({
    logout: () => dispatch(signOut())
  })
)(Header)
