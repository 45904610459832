import React from 'react'
import PropTypes from 'prop-types'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { InputNumber as ANTDInputNumber } from 'antd'

const InputNumber = (props) => (
  <Form.Item label={props.label} required={props.required} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
    <ANTDInputNumber {...props.input} style={{ width: '100%' }} />
  </Form.Item>
)

InputNumber.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  input: PropTypes.object.isRequired
}

InputNumber.defaultProps = {
  required: false
}

export default InputNumber
