import axios from 'axios';

const apiUrl = 'https://hildegard-v1-29brz.ondigitalocean.app/api/';
const betaApiUrl = 'https://beta.hildegard.kernwerk.io/api/';
const hildegardDevUrl = 'https://hildegard-feature-cache-ftqgdy.herokuapp.com/api/';
const goHildegardApiUrl = 'https://algo-hildegard-internal.kernwerk.io/api/';

const hildegard = axios.create({
    baseURL: apiUrl,
});

const hildegardBeta = axios.create({
    baseURL: betaApiUrl,
});

const hildegardDev = axios.create({
    baseURL: hildegardDevUrl,
});

const goHildegard = axios.create({
    baseURL: goHildegardApiUrl
});

export default hildegard;
export { hildegardBeta, hildegardDev, goHildegard };
