import React from 'react';
import PropTypes from 'prop-types';
import { List, Row, Col, Statistic } from 'antd';
import Exercise from './exercise';

const Part = ({ part }) => (
    <>
        <Row style={{ marginBottom: 20 }}>
            <Col span={part.modeTimeCap > 0 ? 8 : 12}>
                <Statistic title="Mode" value={part.mode} />
            </Col>
            <Col span={part.modeTimeCap > 0 ? 8 : 12}>
                {part.mode === 'AMRAP' && <Statistic title="Duration" value={part.modeValue} suffix="min" />}
                {['FT', 'FQ'].includes(part.mode) && part.modeValue > 1 && <Statistic title="Rounds" value={part.modeValue} />}
            </Col>
            {part.modeTimeCap > 0 && (
                <Col span={8}>
                    <Statistic title="TC" value={part.modeTimeCap} suffix="min" />
                </Col>
            )}
        </Row>

        <List
            size="small"
            dataSource={part.elements ?? []}
            renderItem={(element, key) => <Exercise element={element} key={key} />}
            itemLayout="horizontal"
            bordered
        />
    </>
);

Part.propTypes = {
    part: PropTypes.object.isRequired,
};

export default Part;
